import { Link } from "@remix-run/react";
import { Button } from "~/components/ui/Button";
import { Card } from "~/components/ui/Card";

export default function CountryWarning() {
  return (
    <Card className="mx-auto flex max-w-sm flex-col gap-4 bg-secondary text-sm leading-relaxed text-muted-foreground">
      <h4 className="text-xl text-primary">Sorry...</h4>
      <p>
        We'd love for you to use Ranger, but we currently only support users in
        the United States. If you want to see support for your country added,
        you can reach out to us at{" "}
        <a href="mailto:help@rangerbudget.com">help@rangerbudget.com</a>.
      </p>
      <Button>
        <Link to="/signup?bypassCountryNotice">I'm in the US - continue!</Link>
      </Button>
    </Card>
  );
}
